import React, { FC } from "react";
import styled from "styled-components";
import { HeaderTitle } from "src/components/atoms";

export const Header: FC = () => {
  return (
    <HeaderBlock>
      <HeaderTitle>하나행복금융센터</HeaderTitle>
    </HeaderBlock>
  );
};

const HeaderBlock = styled.header`
  width: 100%;

  height: auto;
  display: flex;
  margin: 60px 0;

  @media (max-width: 1150px) {
    max-width: 1080px;
  }

  @media (max-width: 835px) {
    margin: 40px 0;
  }

  @media (max-width: 410px) {
    margin: 40px 0 20px;
  }
`;
